import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "videojs-youtube";
import "./index.css";

const initialOptions = {
  controls: true,
  fluid: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
};

export default function YoutubeVideoPlayer({ videoSrc }) {
  const videoNode = useRef(null);
  const player = useRef(null);
  const initialized = useRef(false);

  useEffect(() => {
    if (videoNode.current && !initialized.current) {
      initialized.current = true; //prevent duplicate initialization
      player.current = videojs(videoNode.current, {
        ...initialOptions,
        ...{
          sources: [
            {
              type: "video/youtube",
              src: videoSrc,
            },
          ],
        },
      }).ready(function () {
        console.log("Player Ready");
      });
    }
    //clear up player on dismount
    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, [videoSrc]);

  return (
    <div className="mx-auto max-w-4xl">
      <video ref={videoNode} className="video-js vjs-big-play-center" />
    </div>
  );
}
