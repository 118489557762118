import pastaroller from "./pastaroller.png";
import carvingclub from "./carvingclub.png";
import bombering from "./bombering.png";
import aboutSquare from "./about_square.png";
import aboutRect from "../../static/me-small.png";

export const whatsNewSections = [
  {
    orientation: "left",
    title: "On the blog",
    text: `
Anything can be a wax carving tool if you put your mind to it!

Take a look in your kitchen to find these utensils I use for wax carving. 
 `,
    link: `/blog/post/unconventional-tools-kitchen`,
    linktext: `Read unconventional kitchen tools`,
    image: pastaroller,
    imagealt: `Close up of bench peg with a pasta roller machine on top`,
  },

  {
    orientation: "right",
    title: `Behind the scenes`,
    text: `
I’m starting a monthly Carving Club! If you want to make more time to be creative this year this might be the club you’re looking for!

Make a piece around a monthly chosen theme from your own studio, and share your progress with other club members online.
 
Push your creativity and carving skills and get inspired for future collections!
`,
    link: `carving-club`,
    linktext: `Learn about Carving Club`,
    image: carvingclub,
    imagealt:
      "Close up shot of hands working at the bench: burring a piece of turquoise wax",
  },

  {
    orientation: "left",
    title: `With the classes`,
    text: `
Are you new to wax carving? The bombe ring class is a perfect place to start.

It’s a classic ring shape to make from wax for a reason!

Learn all the basics of wax carving and carve these chunky high domed rings! 
`,
    link: `class/bombe-ring`,
    linktext: `Show me the bombe ring class!`,
    image: bombering,
    imagealt:
      "3 bombe rings side by side, left blue wax with a carved out triangular pattern, middle blue wax with a wave pattern, left purple wax with a scratchy texture",
  },
];

export const blurbsection = {
  text: `Wax carving is a fun jewellery making technique where you transform an unassuming piece of wax into a beautiful piece of jewellery! Learn to carve rings,  3-dimensional shapes, classic pieces, and bold statement jewellery. 

Anything is possible in wax!

Through video tutorials you learn to carve your own piece of jewellery ready to be cast in the metal of your choice. Want to know what you can make?`,
  linktext: `Yes! Show me the classes!`,
  link: `/classes`,
};

export const aboutsection = {
  title: `Hi I'm Sandy!`,
  text: `I’m the CEO (Chief EVERYTHING Officer) here at Wax Carvers.
  
I did my first jewellery making class in 2012 and haven’t looked back since. I fell in love with wax carving in 2018 and I’m excited to share that love with you!
`,
  linktext: `Want to know more?`,
  link: `/about`,
  squarephoto: aboutSquare,
  rectphoto: aboutRect,
};

export const blogsection = {
  text: `
Do you want to know what tools you need to get started with wax carving?

Do you want to know what wax color you should get?

Do you want to know how you can set up a quick bench when you don’t have space for a dedicated one?

All these topics and more are covered on the blog! A new post is published every other Tuesday. So you can keep coming back to learn more about wax carving.
`,
  linktext: `Let's read that blog!`,
  link: `/blog`,
};
